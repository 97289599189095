/* global userEmail */

(function (window, $) {
  var strings = BatchGeoStrings.getStringsForComponent(
    "BatchGeoAdminSubscription",
  );

  /**
   * Sets up the subscription page's billing credit card update functions
   */
  var BatchGeoAdminSubscriptions = function () {
    this._elements = {};

    this.paymentNonce = null;
    this.clientToken = null;
    this.bin = null;

    // Perform any initialization code here. If it needs to rely on the page elements, put it inside
    // the onReady function later in this file.
    BatchGeo.timer("init");

    this.onReady();
  };

  /**
   * Cache jquery objects to be used later. To be run as the first method
   */
  BatchGeoAdminSubscriptions.prototype._cacheElements = function () {
    this._elements = {
      $formBilling: $('form[name="update_billing_form"]'),
      $ccLastDigits: $("#billing_cc_last4"),
      $ccExpireDate: $("#billing_cc_monthyear"),
      $paypalEmail: $("#paypal_email"),
    };
    this._elements.$submitButton =
      this._elements.$formBilling.find('[type="submit"]');
    this._elements.$loader = $("<div />", {
      addClass: "loading-icon",
      toggle: false,
    }).appendTo(this._elements.$submitButton.parent());
  };

  /**
   * Saves the new credit card and returns confirmation
   * @returns {Promise} The promise response from 3DS dropinInstance requestPaymentMethod
   */
  BatchGeoAdminSubscriptions.prototype.saveBilling = function () {
    var self = this;
    self._toggleLoader(true);

    if (!window.dropinInstance) {
      console.error("DropinInstance undefined. Something went wrong.");
    }

    return window.dropinInstance
      .requestPaymentMethod()
      .then(async function (payload) {
        const formData = self._elements.$formBilling.serializeArray();
        //get user info
        var currentUserInfo = await fetch(
          "/api/braintree-utils/get-current-user-pricing/",
        );
        if (!currentUserInfo.ok) {
          console.error("Could not retreive user data");
          return;
        }
        currentUserInfo = await currentUserInfo.json();

        var threeDSInfo = currentUserInfo.threeDSecureInfo;
        threeDSInfo.nonce = payload.nonce;

        var usIssuance = [
          "United States of America",
          "United States",
          "US",
          "USA",
          840,
        ];
        if (
          payload.type === "CreditCard" &&
          !usIssuance.includes(payload.binData.countryOfIssuance)
        ) {
          threeDSInfo.bin = payload.details.bin;
          // window.dropinInstance.verifyCard();
          var verified = await window.verifyCardWith3DSecure(
            threeDSInfo,
            currentUserInfo.email,
            currentUserInfo.postalCode,
            currentUserInfo.planCost,
          );

          // Inject nonce
          formData.push({
            name: "nonce",
            value: verified.nonce,
          });
        } else {
          formData.push({
            name: "nonce",
            value: payload.nonce,
          });
        }

        // Send payload.nonce to server for updating subscription
        // console.log(formData);
        $.ajax({
          method: "POST",
          url: "/api/admin/subscription/index.php",
          data: formData,
          success: function (response) {
            self.billingSavedSuccess(response, payload);
          },
          error: function (error) {
            $(".billing-modal").hide();
            $("#updateBillingBtn").prop("disabled", true);
            $("#updateBillingBtn").removeClass("green");

            //force user to reload for a new nonce
            BatchGeo.blockAlert(
              `${error.responseJSON.error} Reload the page to update your billing information.`,
              () => window.location.reload(true),
            );
          },
          complete: function () {
            self._toggleLoader(false);
            self.resetCaptcha();
          },
        });
      })
      .catch(function (error) {
        self._toggleLoader(false);
        self.resetCaptcha();
        console.error("requestPaymentMethod fail: ", error.message);
      });
  };

  /**
   * Calling this method should initiate a 3D Secure verification for an existing card. EU users should be able to
   * verify their cards so that their subscriptions continue to process.
   * @param {Event} event anchor tag click event
   */
  BatchGeoAdminSubscriptions.prototype.verifyExistingCardWith3DSecure =
    async function () {
      const verifyWrapper = document.getElementById("verify-card-wrapper");
      // first check that we have what we need
      if (!this.clientToken || !this.paymentNonce) {
        console.error(
          "Cannot verify card without clientToken and paymentNonce. Was there a server error?",
        );
        verifyWrapper.innerHTML =
          "There was an issue, please reload the page and try again.";
        return;
      }

      verifyWrapper.innerHTML = "Card verification in progress...";
      let planCost = "0";
      // try to get the amount for the plan they are signing up for
      try {
        const response = await fetch(
          `/api/braintree-utils/get-current-user-pricing/`,
        );

        // request failed?
        if (!response.ok) {
          throw new Error(`Response not successful: ${await response.text()}`);
        }
        const resData = await response.json();
        // if it work
        planCost = resData.planCost.toString();
      } catch (err) {
        console.error(err);
      }

      const threeDSecureParameters = {
        amount: planCost,
        nonce: this.paymentNonce,
        bin: this.bin,
        onLookupComplete: function (data, next) {
          next();
        },
        email: userEmail,
      };

      braintree.client
        .create({
          // Use the generated client token to instantiate the Braintree client.
          authorization: this.clientToken,
        })
        .then(function (clientInstance) {
          return braintree.threeDSecure.create({
            version: 2, // Will use 3DS2 whenever possible
            client: clientInstance,
          });
        })
        .then(function (threeDSecureInstance) {
          threeDSecureInstance
            .verifyCard(threeDSecureParameters)
            .then(function (response) {
              console.log("verifycard response: ", response);

              verifyWrapper.innerHTML = "Waiting for bank verification...";

              $.ajax({
                method: "POST",
                url: "/api/braintree-utils/", // needs the ending / otherwise it will redirect to the GET route
                data: { nonce: response.nonce },
                success: function (newData) {
                  // ⚠️ renamed 'data' to 'newData' to make sure we were logging correct value
                  console.log(
                    "SUCCESS SENDING VERIFIED CARD NONCE TO SERVER",
                    newData,
                  );
                  verifyWrapper.innerHTML =
                    "Card Verification Successful! You do not need to update your card unless you would like to add a new payment method. If so, please refresh your page.";
                },
                error: function (err) {
                  console.error(
                    "ERROR SENDING VERIFIED CARD NONCE TO SERVER",
                    err,
                  );
                  verifyWrapper.innerHTML =
                    "Error verifying card, please try again or contact BatchGeo support.";
                },
              });
            })
            .catch(function (error) {
              // Handle error
              console.error("Error verifying user card", error);
              verifyWrapper.innerHTML =
                "Error verifying card, please try again or contact BatchGeo support.";
            });
        })
        .catch(function (error) {
          // Handle component creation error
          console.error("Error creating braintree client", error);
          verifyWrapper.innerHTML =
            "Error verifying card, please try again or contact BatchGeo support.";
        });
    };

  /**
   * Callback method for the response from API
   * @param {object} response API response
   */
  BatchGeoAdminSubscriptions.prototype.billingSavedSuccess = function (
    response,
    payload,
  ) {
    if (response.success) {
      var formValues = {};
      this._elements.$formBilling.serializeArray().forEach(function (element) {
        formValues[element.name] = element.value;
      });

      if (payload.type === "CreditCard") {
        $(".account-card-info").show();
        $(".account-paypal-info").hide();
        //update view
        var cardNumber = payload.details.lastFour;
        this._elements.$ccLastDigits.html(cardNumber);
        //set date
        var setdate = new Date();
        setdate.setMonth(parseInt(payload.details.expirationMonth) - 1);
        var expdate = setdate.toLocaleString("default", { month: "long" });
        expdate += " " + payload.details.expirationYear;
        this._elements.$ccExpireDate.html(expdate);
      } else if (payload.type === "PayPalAccount") {
        var email = payload.details.email;
        $(".account-card-info").hide();
        $(".account-paypal-info").show();
        this._elements.$paypalEmail.html(email);
      }

      //clear form
      this._elements.$formBilling.trigger("reset");

      $(".billing-modal").hide();
      BatchGeo.blockAlert(response.success, () => window.location.reload(true));

      // Remove the dropin instance and hide the rest of the modal content so users aren't confused
      $(".credit_card_form").hide();
      window.dropinInstance.teardown();
    } else {
      $(".billing_warning_wrapper").append(
        `<span class='red-text'>${response.error ? response.error : strings.get("ERROR_CANTUPDATE_CC")}</span>`,
      );
    }
  };

  /**
   * Shows the preloader spinning icon
   * @param {boolean} toggledOn value true will hide the submit button and enable loader, vice-versa
   */
  BatchGeoAdminSubscriptions.prototype._toggleLoader = function (toggledOn) {
    this._elements.$submitButton.toggle(!toggledOn);
    this._elements.$loader.toggle(toggledOn);
  };

  /**
   * This resets the captcha if captcha is already defined
   */
  BatchGeoAdminSubscriptions.prototype.resetCaptcha = function () {
    if (typeof grecaptcha !== "undefined") {
      try {
        grecaptcha.reset();
      } catch (error) {
        console.error(error);
      }
    }
  };

  /**
   * The default onReady function to run when a page initializes the BatchGeoAdminSubscriptions object
   */
  BatchGeoAdminSubscriptions.prototype.onReady = function () {
    BatchGeo.timer("onReady");
    var self = this;
    this._cacheElements();

    window.setup3dsDropin(true);

    // form
    this._elements.$formBilling.submit(function (event) {
      $(".billing_warning_wrapper").html("");
      event.preventDefault();
      self.saveBilling();
    });

    $("#verify-card-btn").click(function (e) {
      self.dropinInstance.teardown().then(function () {
        self.verifyExistingCardWith3DSecure(e);
      });
    });
  };

  // Expose the constructor if we need separate instances
  window.BatchGeoAdminSubscriptions = BatchGeoAdminSubscriptions;
})(window, jQuery);
