export async function setup3dsDropin(
  enablePaypal = false,
  container = "#dropin-container",
) {
  // if (window.dropinInstance) await window.dropinInstance.teardown();

  console.log("3DS Dropin: Instantiating...");
  var response = await fetch("/api/braintree-utils/signup-client-token/");
  if (!response) {
    console.log("Failed to retrieve clientToken from server: ", response);
    BatchGeo.blockAlert(
      "An error occured. Please reload the page and try again. If this issue persists, please contact Batchgeo support.",
      () => window.location.reload(true),
    );
  } else {
    response = await response.json();
  }

  $(container).empty();
  const clientToken = response.clientToken;

  // Config docs: https://braintree.github.io/braintree-web-drop-in/docs/current/module-braintree-web-drop-in.html#.create
  const dropInConfig = {
    authorization: clientToken,
    container: container,
    // preselectVaultedPaymentMethod: false,
    hiddenVaultedPaymentMethodTypes: ["card", "paypal"],
  };

  if (enablePaypal) {
    dropInConfig.paypal = {
      flow: "vault",
    };
  }

  try {
    window.dropinInstance = await braintree.dropin.create(dropInConfig);
    console.log("3DS instantiated");
  } catch (e) {
    console.error(
      "Failed to instantiate 3DS Drop-in. Double check your clientToken",
      e,
    );
    BatchGeo.blockAlert(
      "An error occured. Please reload the page and try again. If this issue persists, please contact Batchgeo support.",
      () => window.location.reload(true),
    );
  }
}
window.setup3dsDropin = setup3dsDropin;

export function handle3DSVerifyError(error, logMessage = "", onError) {
  console.error(logMessage, error);
  if (onError) onError(logMessage, error);
}
window.handle3DSVerifyError = handle3DSVerifyError;

export async function verifyCardWith3DSecure(
  threeDSecureInfo,
  email,
  postalCode,
  planCost,
  // eslint-disable-next-line no-unused-vars
  onError = (logMessage, message) => {},
) {
  let { nonce, customerId, clientToken, bin } = threeDSecureInfo;

  if (!nonce || !customerId || !clientToken || !bin) {
    console.error("Cannot verify card without threeDSecureInfo");
  }

  const threeDSecureParameters = {
    amount: planCost || "0",
    // To test error handling, comment out nonce
    nonce,
    bin,
    onLookupComplete: function (data, next) {
      next();
    },
    email,
    billingAddress: {
      postalCode: postalCode,
    },
  };

  var btClient = await braintree.client
    .create({
      // Use the generated client token to instantiate the Braintree client.
      authorization: clientToken,
    })
    .catch(function (error) {
      console.log(threeDSecureInfo);
      handle3DSVerifyError(error, "Error creating braintree client", onError);
      return false;
    });

  var bt3ds = await braintree.threeDSecure
    .create({
      version: 2, // Will use 3DS2 whenever possible
      client: btClient,
    })
    .catch(function (error) {
      handle3DSVerifyError(error, "Error creating braintree client", onError);
      return false;
    });

  var nonceVerfied = await bt3ds
    .verifyCard(threeDSecureParameters)
    .catch(function (error) {
      handle3DSVerifyError(error, "Error verifying user card", onError);
      return false;
    });

  return nonceVerfied;
}
window.verifyCardWith3DSecure = verifyCardWith3DSecure;

export async function reverifyUserCard(planId, addonId, addonCount, taxed) {
  //get estimated cost
  var estimatedPlanCost = await fetch(
    `../../api/braintree-utils/get-plan-pricing/?planId=${planId}&addonType=${addonId}&addonCount=${addonCount}&isWA=${taxed}`,
  );
  if (!estimatedPlanCost.ok) {
    console.error("could not retrieve price data");
    return;
  }
  estimatedPlanCost = await estimatedPlanCost.json();

  //get user info
  var currentUserInfo = await fetch(
    "/api/braintree-utils/get-current-user-pricing/",
  );
  if (!currentUserInfo.ok) {
    console.error("Could not retreive user data");
    return;
  }
  currentUserInfo = await currentUserInfo.json();

  if (
    !currentUserInfo.threeDSecureInfo ||
    estimatedPlanCost.planCost <= currentUserInfo.planCost
  ) {
    // card is not 3ds enabled ||
    //not performing actions that need plan updates
    //don't verify.
    return;
  }

  //verify
  var { threeDSecureInfo, email, postalCode, planCost } = currentUserInfo;
  var verify = await verifyCardWith3DSecure(
    threeDSecureInfo,
    email,
    postalCode,
    planCost,
  );
  return verify;
}
window.reverifyUserCard = reverifyUserCard;

// A template for if we need to wait for user input on 3ds verfication
//
// async function open3dsModal() {
//   var modal = $("#3ds-verification-modal");
//   if (!modal) console.error("Could not find 3ds verification modal");
//   modal.show();
//   await setup3dsDropin(false, "#modal-dropin-container");
//   var result = Promise.withResolvers();
//   $("#verification-submit").click(async (e) => {
//     e.preventDefault();
//     result.resolve(await reverifyUserCard());
//   });
//   return await result.promise;
// }

// eslint-disable-next-line no-undef
// module.exports = {
//   setup3dsDropin: setup3dsDropin,
//   verifyCardWith3DSecure: verifyCardWith3DSecure,
//   handle3DSVerifyError: handle3DSVerifyError,
//   // open3dsModal: open3dsModal,
//   reverifyUserCard: reverifyUserCard,
// };
